<template>
  <div>
    <page-header
      title="Anniversaires" icon="fa fa-birthday-cake"
      :links="pageLinks"
    ></page-header>
    <div class="filter-bar">
      <b-row>
        <b-col :cols="4">
          <date-frame-selector :start-date="startDate" :end-date="endDate" @change="onDateChanged"></date-frame-selector>
        </b-col>
        <b-col :cols="4">
          <div class="buttons">
            <a href @click.prevent="onToday()" class="btn btn-secondary">Aujourd'hui</a>
            <a href @click.prevent="onNextDay()" class="btn btn-secondary">
              Lendemain
            </a>
            <a href @click.prevent="onNextWeek()" class="btn btn-secondary">
              Semaine suivante
            </a>
          </div>
        </b-col>
        <b-col :cols="4" class="text-right">
          <div class="buttons">
            <b-form-checkbox id="activeMember" v-model="activeMember">
              Adhérents actifs
            </b-form-checkbox>
            <b-form-checkbox id="hereOnDay" v-model="hereOnDay">
              Inscrit à un ACM le jour
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
    </div>
    <div ref="pdfAndXls">
      <loading-gif :loading-name="loadingName"></loading-gif>
      <div v-if="!isLoading(loadingName)">
        <div class="empty-text" v-if="items.length === 0">Aucun anniversaire</div>
        <x-table
          id="people"
          :columns="columns"
          :items="items"
          verbose-name="anniversaire"
          verbose-name-plural="anniversaires"
        ></x-table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import DateFrameSelector from '@/components/DateRange/DateFrameSelector.vue'
import XTable from '@/components/Controls/Table/Table.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import { BackendMixin } from '@/mixins/backend'
import { makeIndividual } from '@/types/people'
import { BackendApi, openDocument } from '@/utils/http'
import { slugify } from '@/utils/strings'
import { dateToString } from '@/filters/texts'

export default {
  name: 'birthdays-between',
  mixins: [BackendMixin],
  components: {
    LoadingGif,
    XTable,
    DateFrameSelector,
    PageHeader,
  },
  data() {
    return {
      individuals: [],
      startDate: '',
      endDate: '',
      columns: [],
      activeMember: true,
      hereOnDay: false,
      loadingName: 'birthdays-between',
    }
  },
  watch: {
    activeMember: function() {
      this.loadBirthdays()
    },
    hereOnDay: function() {
      this.loadBirthdays()
    },
  },
  computed: {
    canView() {
      return this.hasPerm('people.view_individual')
    },
    pageLinks() {
      const cssClass = 'btn-secondary' + (this.items.length === 0 ? ' disabled' : '')
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: cssClass,
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: cssClass,
        }
      ]
    },
    items() {
      return this.individuals.map(this.makeItem)
    },
  },
  mounted() {
    this.columns = this.getColumns()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    makeItem(individual) {
      return {
        id: individual.id,
        lastName: individual.lastName,
        firstName: individual.firstName,
        birthDate: individual.birthDate,
        birthYear: dateToString(individual.birthDate, 'YYYY'),
        age: individual.getAgeOn(this.endDate),
        individual: individual,
      }
    },
    getColumns() {
      return [
        {
          name: 'lastName',
          label: 'Nom',
          maxWidth: '100px',
          onClick: item => {
            this.showIndividualSidebar(item.individual)
          },
          isLink: item => {
            return true
          },
        },
        {
          name: 'firstName',
          label: 'Prénom',
          maxWidth: '100px',
        },
        {
          name: 'birthDate',
          label: 'Date',
          dateFormat: 'D MMMM',
        },
        {
          name: 'birthYear',
          label: 'Année',
          displayAs: 'number',
        },
        {
          name: 'age',
          label: 'Âge',
          displayAs: 'number',
        }
      ]
    },
    onDateChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
      this.loadBirthdays()
    },
    onToday() {
      const day = moment()
      this.startDate = day.format('YYYY-MM-DD')
      this.endDate = day.format('YYYY-MM-DD')
      this.loadBirthdays()
    },
    onNextDay() {
      let day = this.startDate ? moment(this.startDate) : moment()
      day = day.add(1, 'days')
      this.startDate = day.format('YYYY-MM-DD')
      this.endDate = day.format('YYYY-MM-DD')
      this.loadBirthdays()
    },
    onNextWeek() {
      let day = this.startDate ? moment(this.startDate) : moment()
      while (true) {
        day = day.add(1, 'days')
        if (day.weekday() === 0) {
          break
        }
      }
      this.startDate = day.format('YYYY-MM-DD')
      let day2 = day.add(6, 'days')
      this.endDate = day2.format('YYYY-MM-DD')
      this.loadBirthdays()
    },
    async loadBirthdays() {
      if (this.startDate && this.endDate) {
        let url = '/tools/api/birthdays-between/' + this.startDate + '/' + this.endDate + '/?'
        if (this.activeMember) {
          url += '&active=1'
        }
        if (this.hereOnDay) {
          url += '&here=1'
        }
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.individuals = resp.data.map(elt => makeIndividual(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async excelMe() {
      this.printing = true
      const that = this
      this.$nextTick(
        async function() {
          const docUrl = '/documents/table-to-excel/<key>/'
          const docSlug = 'anniversaires'
          const docContent = that.$refs.pdfAndXls.innerHTML.toString()
          try {
            await openDocument(docUrl, docSlug, docContent)
          } catch (err) {
            await this.addError(that.getErrorText(err))
          }
          that.printing = false
        }
      )
    },
    async printMe() {
      this.printing = true
      const that = this
      this.$nextTick(
        async function() {
          let docUrl = '/documents/standard/<key>/pdf/'
          const docSlug = 'anniversaires'
          const docContent = that.$refs.pdfAndXls.innerHTML.toString()
          try {
            await openDocument(docUrl, docSlug, docContent)
          } catch (err) {
            await this.addError(that.getErrorText(err))
          }
          that.printing = false
        }
      )
    },
  },
}
</script>

<style scoped>
.buttons {
  margin-top: 35px;
}
.buttons a {
  margin-top: 2px;
  margin-right: 3px;
}
.filter-bar {
  padding: 5px 10px;
  background: #eee;
}
</style>
